.projects {
  max-width: 100%;
  position: relative;
  background-color: rgba(15, 23, 42);
  padding: 0 120px;
  justify-content: space-between;
}

.wrapper-projects-list {
  display: flex;
  justify-content: space-between;
}

.projects-left {
  height: 100%;
  position: sticky;
  top: 0;
  width: 30%;
  padding-top: 80px;
}

.projectList {
  place-items: center;
  width: 68%;
  margin-top: 19%;
}

.projects-header {
  font-size: 3rem;
  font-weight: 100;
  margin-bottom: 100px;
  color: #fff;
  text-align: start;
  margin-top: 0;
  display: inline-block;
  position: relative;
  z-index: 0;
  text-transform: uppercase;
}

.projects-header::after {
  content: ''; 
  position: absolute; 
  left: 0;
  right: 0;
  bottom: 5px; 
  height: 0.2em; 
  background-color: rgb(0, 207, 254); 
  z-index: -1; 
  width: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
}

.projects .projectTitle {
  text-align: center;
  color: #3e497a;
  font-size: 60px;
  font-weight: bolder;
}

.projectName {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.projectName h1 {
  margin: 0;
}

.title-project-wrapper {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
}

.projectItem h1 {
  font-size: 18px;
  color: #333333;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .projects {
   padding: 0;
  }

  .wrapper-projects-list {
    flex-direction: column;
  }

  .projects-left {
    position: initial;   
    width: 76%;
    margin: auto; 
    padding-top: 30px;
  }

  .projects-left h2 {
    font-size: 2rem;
    margin-bottom: 70px;
  }

  .projectList {
    padding: 50px 30px;
    width: auto;
    margin-top: 0;
  }

  .projectItem {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .projects {
   padding: 0;
  }

  .wrapper-projects-list {
    flex-direction: column;
  }

  .projects-left {
    position: initial;   
    width: 76%;
    margin: auto; 
    padding-top: 30px;
  }

  .projects-left h2 {
    font-size: 2rem;
    margin-bottom: 70px;
  }

  .projectList {
    padding: 50px 30px;
    width: auto;
    margin-top: 0;
  }

  .projectItem {
    padding: 0;
  }
}


