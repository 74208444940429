.filter {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: width 0.15s ease;
  min-width: 80px;
  margin-bottom: 20px;
  width: fit-content;
}

.filter-bar-background {
  width: 40px;
  height: 100%;
  background-color: rgb(100, 116, 139);
  transition: width 0.15s ease;
  margin-left: -10px;
  margin-right: -10px;
}

.filter-bar-label {
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  color: rgb(100, 116, 139);
}

.filter:hover .filter-bar-background,
.filter.selected .filter-bar-background {
  width: 80px;
  transition: width 0.15s ease;
  background-color: rgb(226, 232, 240); 
}

.filter:hover .filter-bar-label,
.filter.selected .filter-bar-label {
  color: rgb(226, 232, 240); 
}

.filter-bar-left {
  display: flex;
  align-items: center;
  margin-right: 10px;
  height: 1px;
}

@media only screen and (max-width: 768px) {
  .filter-bar-label {
    font-size: 1rem;
  }
}