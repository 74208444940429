html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  justify-content: center;
  font-weight: 300;
  overflow-x: hidden;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 0.9rem;
  }
}