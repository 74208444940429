.mouse-light {
  position: absolute;
  width: 700px;
  height: 700px;
  background: radial-gradient(circle, rgba(29, 78, 216, 0.1), transparent 80%);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 400;
}

.on-bottom {
  display: none;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .mouse-light {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .mouse-light {
    display: none;
  }
}




