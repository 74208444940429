.skills {
  position: relative;
  z-index: 300;
  text-align: center;
  padding: 130px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills h2 {
    margin-top: 0;
    margin-bottom: 85px;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
    position: relative;
    display: inline-block;
    z-index: 0;
    text-transform: uppercase;
}

.skills h2::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 5px;
    height: 0.2em;
    background-color: rgb(0, 207, 254);
    z-index: -1;
    width: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
}

.skills i {
 font-size: 5rem;
 margin: 10px 30px;
}

.skills-title {
  position: relative;
  z-index: 400;
  color: #fff;
  text-align: end;
  margin-right: 13%;
  font-size: 1.2rem;
}

.skills-icons {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 78%;
  padding: 150px 0 70px 0;
  margin: auto;
}

.symfony-icon {
  color: #fff!important;
}

.coder-word {
  font-weight: 500;
}

.skills-block {
  line-height: 30px;
  font-weight: 200;
  color: rgb(195 205 218);
  font-size: 1.1rem;
  text-align: start;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
}

.skills-block p {
  margin: 0;
}

.skills-block p span:first-child {
  margin-right: 10px;
}

.skills-training {
  font-weight: 600;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .skills {
    padding: 50px 30px;
    z-index: auto;
  }

  .skills h2 {
    font-size: 2rem;
    margin-bottom: 70px;
    text-align: start;
  }

  .skills-title {
    z-index: auto;
  }

  .skills i {
    font-size: 4rem;
  }

  .skills-block {
    font-size: 1rem;
    width: 95%;
    padding: 0;
  }

  .skills-icons {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 90px 0 10px 0;
    margin: auto;

  }
}

@media only screen and (max-width: 768px) {
  .skills {
    padding: 50px 30px;
    z-index: auto;
  }

  .skills h2 {
    font-size: 2rem;
    margin-bottom: 70px;
    text-align: center;
  }

  .skills h2::after {
    width: calc(65%);
  }

  .skills-title {
    z-index: auto;
  }

  .skills i {
    font-size: 4rem;
  }

  .skills-block {
    font-size: 1rem;
    width: 95%;
    padding: 0;
  }

  .skills-icons {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 90px 0 10px 0;
    margin: auto;

  }
}

