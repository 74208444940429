.footer {
  position: relative;
  z-index: 300;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px solid #ffffff54;
  padding-top: 10px;
}

.footer p {
  margin-top: 10px;
  color: #fff;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .footer .social-links svg {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    z-index: auto;
    margin-top: 80px;
  }

  .footer .social-links svg {
    font-size: 30px;
  }
}