:root {
  --background-opacity: 0; 
}

.parallax-overlay {
  height: 100vh;
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.71, 0.35, 0.26, 0.83);
}

.parallax-overlay:after {
  background: rgba(15, 23, 42, var(--background-opacity));
  content: "";
  background-position: center center;
  position: absolute;
  inset: -350px 0px;
  will-change: transform;
  position: fixed!important;
  max-width: 1440px;
  margin: auto;
}

.parallax-overlay > div:first-child {
  position: fixed!important;
  max-width: 1440px;
  margin: auto;
  background-size: cover!important;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  z-index: 300;
  transform: translate(-50%, -50%);
  text-align: start;
  width: 81%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hello {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 200;
  margin-bottom: 5px;
  font-family: Inter;
}

.left-block {
  width: 45%;
}

.right-block {
  width: 45%;
}

.social-links {
  text-align: center;
}

.right-block p {
  line-height: 35px;
  font-weight: 200;
  color: rgb(195 205 218);
  font-size: 1.4rem;
  text-align: start;
  background-color: #041028de;
  padding: 20px;
  border-radius: 5px;
}

.content h1 {
  font-size: 3rem;
  color: #fff;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}

.content-description {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 100;
  color: #fff;
}

.cursor {
  display: inline-block;
  width: 2px; 
  height: 1.2em; 
  background-color: #fff; 
  animation: cursorBlink 0.9s infinite; 
  vertical-align: -0.2em;
}

.quote-intro {
  font-size: 3rem;
  font-weight: 500;
  font-style: italic;
  padding: 0 5px;
  color: #FF8C42;
  position: relative;
  top: 10px;
}

.right-block p span:first-child {
  padding-right: 10px;
}

.hello-word {
  font-weight: 700;
}

.home-words {
  font-weight: 500;
}

@keyframes cursorBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.line {
  width: 40px;
  height: 2px;
}

.separation-line {
  border: 0;
  height: 1px;
  background: #FF8C42; 
  width: 40%; 
  margin: 40px auto;
}

.social-links svg {
  font-size: 40px;
  margin: 0 6px;
  color: #fff;
}

.parallax-overlay {
  --background-opacity: 0.9;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .content {
    flex-direction: column;
    position: static;
    transform: matrix(1, 0, 0, 1, 0, 0);
    width: 100%;
    margin-top: -200px;
  }

  .content-description {
    font-size: 2rem;
    line-height: 30px; 
  }

  .left-block {
    width: 90%;
  }

  .right-block {
    width: 90%;
  }

  .right-block p {
    font-size: 1rem;
    line-height: 28px;
    margin-top: 70px;
  }

  .icon-scroll {
    display: none;
  }

  .content h1 {
    font-size: 2rem;
  }

  .hello {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
    position: static;
    transform: matrix(1, 0, 0, 1, 0, 0);
    width: 100%;
    margin-top: 70px;
  }

  .parallax-overlay {
    position: fixed !important;
  }

  .content-description {
    font-size: 2rem;
    line-height: 30px; 
  }

  .content h1 {
    font-size: 2rem;
  }

  .content-description {
    font-size: 2rem;
    line-height: 30px; 
  }

  .left-block {
    width: 83%;
    text-align: start;
    padding: 133px 0 50px 0;
  }

  .right-block {
    width: 90%;
  }

  .right-block p {
    font-size: 1rem;
    line-height: 28px;
  }

  .icon-scroll {
    display: none;
  }

  .hello {
    font-size: 1.1rem;
  }

  .separation-line {
    border: 0;
    height: 1px;
    background: #FF8C42;
    width: 100%;
    margin: 40px 0 40px 0;
    margin-left: 0;
  }
}

