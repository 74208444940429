.header-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
  margin-bottom: 15px;
  margin-right: 70px;
  transition: background-color 0.3s;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 0 25px;
  font-weight: 200;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(15, 23, 42, 0.15);
  backdrop-filter: blur(6px);
  height: 55px;
  margin: 0;
  border-bottom: 1px solid #ebeef23b;
  padding-right: 70px;
  z-index: 1000; 
  max-width: 1440px;
}

.sticky ul li a {
  color: rgb(226, 232, 240);
}

.sticky ul {
  justify-content: flex-end;
}

.active {
  color: #FF8C42!important;
  font-weight: 600;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #f5f5f5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

.bm-item {
  display: inline-block;
  padding: 0.8em;
  outline: none;
  color: rgba(15, 23, 42, 1);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap {
  left: 0!important;
  top: 0;
}

.bm-burger-button button {
  color: #fff;
}

.contact-top {
  color: orange;
  font-weight: 700;
}

@media screen and (min-width: 1451px) {
  .sticky {
    right: 0;
    left: 0;
    margin: auto;
    padding: 0;
  }
}








