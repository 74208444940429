.projectItem {
  display: flex;
  margin-bottom: 3rem;
  padding: 1.3rem;
  overflow: hidden; 
  transition: box-shadow 0.2s ease-in;
  font-size: 1.1rem;
}

.projectItem:hover {
  box-shadow: 0 0 0 #0000, 0 0 0 #0000, inset 0 1px 0 0 rgba(148, 163, 184, 0.1);
  transition: 0.2s ease-in;
  cursor: pointer;
  border-radius: 3px;
  background-color: rgb(23 36 57 / 50%);
}

.projectItem:hover .project-content h3 {
  color: rgb(0, 207, 254);
}

.project-img {
  width: 30%;
  height: 200px;
  overflow: hidden;
}


.project-img img {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.projectItem:hover .project-img img {
  transform: scale(1.1); 
}

.project-content {
  width: 70%;
  padding: 0 30px;
  font-weight: 200;
}

.project-content h3 {
  margin-top: 0;
  font-weight: 400;
  color: rgb(226, 232, 240);
  transition: color 0.2s ease-in;
  margin-right: 5px;
}

.project-content p {
  color: rgb(195, 205, 218);
  line-height: 30px;
  font-size: 1.1rem;
}

.bgImage {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 2px;
}

.project-content-skills {
  display: flex;
}

.project-content-skills p {
  background-color: rgb(144 201 255 / 10%);
  padding: 0.25rem 0.75rem;
  margin-right: 17px;
  border-radius: 9999px;
  color: rgb(0, 207, 254);
}

.project-content-header {
  display: flex;
}

.project-content-header svg {
   color: rgb(226, 232, 240);
   position: relative;
   top: 7px;
}

.projectItem:hover .project-content-header svg {
  color: rgb(0, 207, 254);
  transform: translate(5px, -5px) ;
  transition: transform 0.2s ease-in-out;
}

a {
  text-decoration: none;
}

.challenges-and-solutions {
  color: rgb(226, 232, 240)!important;
  font-weight: 400;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .project-content {
    width: auto;
    padding: 0;
    margin-top: 20px;
    font-size: 1.1rem;
    padding-bottom: 25px;
    border-bottom: 1px solid #ebeef23b;
  }

  .project-content-skills {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .project-img {
    width: 50%;
    text-align: center;
  }

  .projectItem {
    pointer-events: none; 
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
  }

  .projectItem .project-content h3 {
    pointer-events: none; 
    color: rgb(0, 207, 254);
  }

  .project-content p {
    font-size: 1rem;
  }

  .challenges-and-solutions {
    font-size: 1.1rem!important;
  }
}


@media only screen and (max-width: 768px) {
  .project-content {
    width: auto;
    padding: 0;
    margin-top: 20px;
    font-size: 1.1rem;
    padding-bottom: 25px;
    border-bottom: 1px solid #ebeef23b;
  }

  .project-content p {
    font-size: 1rem;
  }

  .challenges-and-solutions {
    font-size: 1.1rem!important;
  }

  .projectItem {
    pointer-events: none; 
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, inset 0 1px 0 0 rgba(148, 163, 184, 0.1);
    cursor: pointer;
    border-radius: 3px;
  }

  .projectItem .project-content h3 {
    pointer-events: none; 
    color: rgb(0, 207, 254);
  }

  .project-content-skills {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .project-img {
    width: 100%;
    text-align: center;
  }
}