.contact {
  z-index: 300;
  position: relative;
  padding: 100px 90px;
  margin-top: 50px;
  text-align: center;
}

.contact h2 {
  text-align: center;
  margin-bottom: 70px;
  color: #fff;
}

form {
  text-align: center;
}

form input {
  background: transparent;
  margin-bottom: 2rem;
  resize: none;
  color: rgb(148, 163, 184);
  box-shadow: none !important;
  padding: 24px 21px !important;
  border-color: var(--border-color) !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 50%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ced4da!important;
  -webkit-text-fill-color: rgb(148, 163, 184);;
  -webkit-box-shadow: 0 0 0px 1000px #ced4da inset;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  background: transparent;
  margin-bottom: 2rem;
  resize: none;
  color: rgb(148, 163, 184);
  border: 1px solid #ced4da;
  padding: 24px 21px;
  width: 50%;
  height: 120px;
  font-family: Arial, Helvetica, sans-serif;
}

::placeholder {
  color: rgb(148, 163, 184);
}

.contact h2 {
  margin-top: 0;
  font-size: 3rem;
  color: rgb(226, 232, 240);
  font-weight: 100;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  z-index: 0;
}

.contact h2::after {
  content: ''; 
  position: absolute; 
  left: 0;
  right: 0;
  bottom: 5px; 
  height: 0.2em; 
  background-color: rgb(0, 207, 254); 
  z-index: -1; 
  width: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
}

.contact button {
  background-color: rgb(144 201 255 / 10%);
  padding: 0.25rem 0.75rem;
  margin-right: 17px;
  padding: 15px 40px;
  color: rgb(0, 207, 254);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.error-message {
  margin-top: 40px;
  color: red;
  text-align: center;
}

.success-message {
  margin-top: 40px;
  color: #fff;
  text-align: center;
}

form input:focus,
textarea:focus {
  outline: none;
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .contact h2:after {
    width: calc(100% + 15px);
  }

  .contact h2 {
    font-size: 2.3rem;
  }

  .contact {
    padding: 0 30px;
    margin-top: 10px;
    z-index: auto;
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    padding: 0 30px;
    margin-top: 10px;
    z-index: auto;
  }

  form input {
    width: 80%;
  }

  textarea {
    width: 80%;
  }

  .contact h2:after {
    width: calc(100% + 15px);
  }

  .contact h2 {
    font-size: 2.3rem;
  }

  .contact button {
    font-size: 0.8rem;
  }
}